import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Ekologické šíření druhů" />
    <h1>Ekologické šíření druhů</h1>

<p>Ekologick&eacute; &scaron;&iacute;řen&iacute; druhů je <Link to="/biogeografie/disperze/">typ disperze</Link>, při n&iacute;ž se druh &scaron;&iacute;ř&iacute; z m&iacute;sta narozen&iacute; pouze v r&aacute;mci sv&eacute;ho are&aacute;lu, tedy tam, kde už byl. Ekologick&eacute; &scaron;&iacute;řen&iacute; je de facto neust&aacute;l&yacute;m procesem, ale jen vz&aacute;cně vy&uacute;st&iacute; v re&aacute;lnou změnu geografick&eacute;ho roz&scaron;&iacute;řen&iacute; druhu.</p>
<p>Do ekologick&eacute;ho &scaron;&iacute;řen&iacute; se zahrnuj&iacute; i sez&oacute;nn&iacute; migrace živočichů za potravou či tzv. <strong>irupce</strong>, epizodick&eacute; exploze ve velikosti populac&iacute; a rozsahu are&aacute;lů živočichů, kter&eacute; jsou <a href="https://www.youtube.com/watch?v=rfnH7LCFQL4">typick&eacute; např&iacute;klad pro sarančata</a>.</p>
<hr />
<h2>Způsoby ekologick&eacute;ho &scaron;&iacute;řen&iacute; u&nbsp;rostlin</h2>
<p>Pro ekologickou disperzi u rostlin je důležit&yacute;m pojmem <strong>diaspora</strong>, kterou se v tomto kontextu rozum&iacute; č&aacute;st rostliny určen&aacute; k &scaron;&iacute;řen&iacute;, a to ať už jde o semeno, plod, sp&oacute;ru aj.</p>
<p>Ekologick&eacute; &scaron;&iacute;řen&iacute; diaspory rostliny se pak může d&iacute;t několika způsoby:</p>
<ul>
<li><strong>autochorie</strong></li>
<li><strong>barochorie</strong></li>
<li><strong>rozrůst&aacute;n&iacute;</strong></li>
<li><strong>anemochorie</strong></li>
<li><strong>hydrochorie</strong></li>
<li><strong>zoochorie</strong></li>
<li><strong>myrmekochorie</strong></li>
</ul>
<h3>Autochorie</h3>
<p>Autochorie je způsob &scaron;&iacute;řen&iacute; vlastn&iacute;mi silami. Diaspora rostliny, v tomto př&iacute;padě semena, jsou vystřelov&aacute;na apar&aacute;tem rostliny. Dostřel semen z&aacute;vis&iacute; zejm&eacute;na na s&iacute;le vystřelovac&iacute;ho apar&aacute;tu, hmotnosti, tvaru a povrchu semen a v&yacute;&scaron;ce um&iacute;stěn&iacute; semen.</p>
<p>Autochorie je čast&aacute; u druhů s lusky a tobolkami. Nejběžněj&scaron;&iacute;m př&iacute;kladem autochorie z česk&eacute; fl&oacute;ry jsou net&yacute;kavky.</p>
<h3>Barochorie</h3>
<p>Barochorie je ekologick&eacute; &scaron;&iacute;řen&iacute; diaspor prostřednictv&iacute;m gravitace, tedy pohybem semene p&aacute;dem na zem. Pokud je barochorie hlavn&iacute;m způsobem disperze rostliny, jej&iacute; semena jsou pak za t&iacute;mto &uacute;čelem vět&scaron;inou velk&aacute; a těžk&aacute;, maj&iacute;c&iacute; kulovit&yacute; či elipsoidn&iacute; tvar.</p>
<p>Barochori&iacute; se v podm&iacute;nk&aacute;ch česk&eacute;ho prostřed&iacute; &scaron;&iacute;ř&iacute; např&iacute;klad dub či j&iacute;rovec (ka&scaron;tan).</p>
<h3>Rozrůst&aacute;n&iacute;</h3>
<p>Rozrůst&aacute;n&iacute;m se m&iacute;n&iacute; &scaron;&iacute;řen&iacute;m klon&aacute;ln&iacute;m rozrůst&aacute;n&iacute;m kořenů, oddenků nebo &scaron;lahounů. Jde sice o jednoduch&yacute; mechanismus, ale trp&iacute; pomalost&iacute; a malou efektivitou.</p>
<p>Mezi rostliny typicky rostouc&iacute; v Česku a &scaron;&iacute;ř&iacute;c&iacute; se pomoc&iacute; rozrůst&aacute;n&iacute; patř&iacute; např&iacute;klad lipnicovit&eacute; a &scaron;&aacute;chorovit&eacute; druhy, kosatec, pryskyřn&iacute;k plaziv&yacute; či jahodn&iacute;k.</p>
<h3>Anemochorie</h3>
<p>U anemochorie je disperzn&iacute;m m&eacute;diem v&iacute;tr. Ekologick&eacute; &scaron;&iacute;řen&iacute; větrem je t&iacute;m nejčastěj&scaron;&iacute;m způsobem disperze v ř&iacute;&scaron;i rostlin, nen&iacute; v&scaron;ak ve v&scaron;ech biotopech stejně zastoupen. Je sice velmi v&yacute;hodn&eacute; na otevřen&yacute;ch stanovi&scaron;t&iacute;ch jako jsou stepi, pou&scaron;tě, hory či tundra, ale např&iacute;klad v podrostech stromů je velmi nev&yacute;hodn&yacute;.</p>
<p>D&eacute;lka doletu diaspory rostliny z&aacute;vis&iacute; na př&iacute;tomnosti l&eacute;tac&iacute;ho zař&iacute;zen&iacute;, s&iacute;le aktu&aacute;ln&iacute;ho větru, hmotnosti diaspory a um&iacute;stěn&iacute; jej&iacute;ho těži&scaron;tě a na poč&aacute;tečn&iacute; v&yacute;&scaron;ce.</p>
<p>Druhy využ&iacute;vaj&iacute;c&iacute; &scaron;&iacute;řen&iacute; pomoc&iacute; větru si osvojuj&iacute; obvykle různ&eacute; anemochorn&iacute; strategie:</p>
<ul>
<li>minimalizace hmotnosti a maximalizace množstv&iacute; diaspory (velk&eacute; množstv&iacute; sp&oacute;rů či mal&yacute;ch semen; např&iacute;klad kapraďorosty)</li>
<li>př&iacute;tomnost l&eacute;tac&iacute;ho zař&iacute;zen&iacute; snižuj&iacute;c&iacute; relativn&iacute; hmotnost a brzd&iacute;c&iacute; p&aacute;d diaspory (např&iacute;klad pampeli&scaron;ky, l&iacute;py, jasany)</li>
<li>strategie stepn&iacute;ho běžce (semena se &scaron;&iacute;ř&iacute; pomoc&iacute; cel&eacute; rostliny, kter&aacute; m&aacute; kulovit&yacute; tvar a ve větru se přesunuje, typicky zn&aacute;zorněno ve westernech)</li>
</ul>
<a href="https://www.youtube.com/watch?v=hsWr_JWTZss"><p>Stepn&iacute; bežci (anglicky <em>Tumbleweed</em>) jako invazivn&iacute; rostlina v USA</p></a>
<h3>Hydrochorie</h3>
<p>Při hydrochorii se diaspory &scaron;&iacute;ř&iacute; tekouc&iacute; vodou. Podle typu tekouc&iacute; vody, prostřednictv&iacute;m n&iacute;ž uskutečňuje rostlina disperzi, rozli&scaron;ujeme tyto druhy hydrochorie:</p>
<ul>
<li>pomoc&iacute; de&scaron;ťov&eacute; vody (tzv. ombrochorie) - kr&aacute;tk&eacute; vzd&aacute;lenosti, využ&iacute;v&aacute; např. &scaron;alvěj</li>
<li>pomoc&iacute; potoků a řek - na vzd&aacute;lenost několika metrů až des&iacute;tek kilometrů, využ&iacute;v&aacute; např. vrba</li>
<li>pomoc&iacute; mořsk&yacute;ch proudů (tzv. talassochorie) - až tis&iacute;ce kilometrů, využ&iacute;v&aacute; např. kokosovn&iacute;k</li>
</ul>
<p>U rostlin využ&iacute;vaj&iacute;c&iacute; hydrochorii se nejčastěji objevuj&iacute; tyto adaptace:</p>
<ul>
<li>př&iacute;tomnost plov&aacute;ků obsahuj&iacute;c&iacute; vzduch</li>
<li>tvrdosemennost pro rezistenci proti po&scaron;kozen&iacute; vodou</li>
<li>využit&iacute; vegetativn&iacute;ch diaspor, např. oddenků a větv&iacute;</li>
</ul>
<h3>Zoochorie</h3>
<p>Zoochorie je typ disperze rostlin, při n&iacute;ž se diaspory &scaron;&iacute;ř&iacute; d&iacute;ky živočichům, nejčastěji pak pomoc&iacute; pt&aacute;ků, savců, hmyzu či člověka. Jde o efektivn&iacute; a c&iacute;len&yacute; způsob (dle habitu živočicha) ekologick&eacute;ho &scaron;&iacute;řen&iacute; v ř&aacute;du metrů i v&iacute;ce než des&iacute;tek kilometrů.</p>
<p>Rozli&scaron;ujeme dva typy zoochorie, a to:</p>
<ul>
<li><strong>epizoochorie </strong>(povrch těla živočicha)</li>
<li><strong>endozoochorie</strong> (uvnitř těla živočicha)</li>
</ul>
<h4>Epizoochorie</h4>
<p>Epizoochorie je &scaron;&iacute;řen&iacute; rostlin pomoc&iacute; diaspor rozn&aacute;&scaron;en&yacute;ch na povrchu těla živočicha. Rozli&scaron;ujeme dva typy epizoochorie:</p>
<ul>
<li><strong>prav&aacute; epizoochorie</strong> (diaspory jsou na povrchu živočicha přilnav&eacute;, jsou opatřeny h&aacute;čky, slizem, chloupky či jin&yacute;mi adaptacemi)</li>
<li><strong>neprav&aacute; epizoochorie</strong> (diaspory nejsou opatřeny ž&aacute;dnou adaptac&iacute; a &scaron;&iacute;ř&iacute; se nalepen&eacute; spolu s bl&aacute;tem nalepen&yacute;m na povrch živočicha)</li>
</ul>
<p>Př&iacute;kladem prav&eacute; epizoochorie je sv&iacute;zel př&iacute;tula (<em>Galium aparine</em>) či lopuch plstnat&yacute; (<em>Arctium tomentosum</em>).</p>
<h4>Endozoochorie</h4>
<p>Endozoochorie je &scaron;&iacute;řen&iacute; rostlin pomoc&iacute; diaspor, kter&eacute; jsou uvnitř těla živočicha. Rozli&scaron;ujeme dva typy endozoochorie:</p>
<ul>
<li><strong>prav&aacute; endozoochorie</strong> (speci&aacute;ln&iacute; adaptace diaspor pro &scaron;&iacute;řen&iacute; přes vnitřek těla živočicha, např. odolnost k tr&aacute;vic&iacute;m &scaron;ťav&aacute;m, dužnatost, n&aacute;padn&aacute; barva, kl&iacute;čivost po průchodu tr&aacute;vic&iacute;m traktem aj.)</li>
<li><strong>neprav&aacute; endozoochorie</strong> (bez speci&aacute;ln&iacute; adaptace diaspor, často doch&aacute;z&iacute; ke ztr&aacute;tě kl&iacute;čivosti při průchodu tr&aacute;vic&iacute;m traktem)</li>
</ul>
<p>Mezi př&iacute;klady prav&eacute; endozoochorie u rostlin v česk&eacute;m prostřed&iacute; patř&iacute; ostružin&iacute;k jilmolist&yacute; (<em>Rubus ulmifolius</em>), jeř&aacute;b ptač&iacute; (<em>Sorbus aucuparia</em>) či jmel&iacute; b&iacute;l&eacute; (<em>Viscum album</em>).</p>
<h3>Myrmekochorie</h3>
<p>Myrmekochorie je podtyp zoochorie, při n&iacute;ž doch&aacute;z&iacute; k &scaron;&iacute;řen&iacute; diaspor rostlin pomoc&iacute; mravenců. K disperzi rostliny pak doch&aacute;z&iacute; na kr&aacute;tk&eacute; vzd&aacute;lenosti, ale za to hustě. Adaptacemi na myrmekochorii jsou nejčastěji semena s vysok&yacute;m obsahem cukru nebo oleje, někdy obsahuj&iacute;c&iacute; tzv. mas&iacute;čko (odborněji ariloid).</p>
<p>Myrmekochorie se nejčastěji objevuje u druhů jarn&iacute;ch a podzimn&iacute;ch, zejm&eacute;na pak <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">geofytů</Link>. Z česk&eacute; fl&oacute;ry využ&iacute;v&aacute; tento způsob ekologick&eacute;ho &scaron;&iacute;řen&iacute; bramboř&iacute;k břečťanolist&yacute; (<em>Cyclamen hederifolium</em>) či dymnivka (<em>Corydalis</em>&nbsp;sp.).</p>
<hr />
<h2>Sez&oacute;nn&iacute; migrace živočichů</h2>
<p>Sez&oacute;nn&iacute; migrac&iacute; živočichů se rozum&iacute; každoročn&iacute; pohyby organismů z jednoho pravidelně obydlen&eacute;ho geografick&eacute;ho regionu do regionu jin&eacute;ho, a to za &uacute;čelem:</p>
<ul>
<li>z&iacute;sk&aacute;n&iacute; potravy,</li>
<li>rozmnožov&aacute;n&iacute; se,</li>
<li>nebo vyhnut&iacute; se nepř&iacute;zniv&yacute;m podm&iacute;nk&aacute;m.</li>
</ul>
<p>Euare&aacute;l je oblast, ve kter&eacute; dan&yacute; se dan&yacute; migruj&iacute;c&iacute; druh rozmnožuje. Epiare&aacute;l je pak region, do kter&eacute;ho druh migruje, aby se vyhnul nepř&iacute;zniv&yacute;m podm&iacute;nk&aacute;m (např. vla&scaron;tovky odl&eacute;taj&iacute; tzv. "do tepl&yacute;ch krajin"). <strong>Soubor epiar&aacute;lu a euar&aacute;lu tvoř&iacute; dohromady holoare&aacute;l</strong>. Jedinci se mezi těmito dvěma are&aacute;ly pohybuj&iacute; zpravidla po stejn&yacute;ch migračn&iacute;ch tras&aacute;ch.</p>

    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Ekologické šíření druhů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/disperze/"><button className="inv">&larr; Disperze druhů</button></Link>
    <Link to="/biogeografie/disperze/biogeograficke-sireni/"><button className="inv">Biogeografické šíření &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
